<template>
  <div>
    <router-link :to="{name: 'index'}">
      <img src="@/assets/404ru.png"/>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'P404',
  props: {
    //
  }
}
</script>

<style scoped>
.box {
  margin-top: 5rem;
}
</style>
